<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import PhoneInput from '@/components/PhoneInput.vue'

withDefaults(
  defineProps<{
    loading: boolean
  }>(),
  {
    loading: false,
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['document/DialogSupplyEidData']['contextData']): void
}>()

const { t } = useI18n()

const data = reactive({
  phoneCompliant: false,
  phoneNumber: '',
})
</script>

<template>
  <base-dialog
    data-cy="supplyEidDataDialog"
    max-width="630"
    :title="t('viewer.eid_data.dialog.title')"
    :disabled="!data.phoneCompliant"
    :loading="loading"
    :action-text="t('global.save')"
    :cancel-text="t('global.close')"
    @action="emit('action', { mobile: data.phoneNumber })"
    @close="emit('close')"
  >
    {{ $t('viewer.eid_data.dialog.text') }}
    <phone-input v-model="data.phoneNumber" class="mt-6" @phone-number-compliant="data.phoneCompliant = $event" />
  </base-dialog>
</template>
